<template>
  <section class="policy-page">
    <div class="container">
      <div class="policy-content" v-html="content" />
      <router-link v-if="!isAuth && content" class="btn btn--primary" to="/">
        На главную
      </router-link>
    </div>
  </section>
</template>

<script>
import { useApi } from '@/api'
import { useToast } from 'vue-toastification'
import { useAuth } from '@websanova/vue-auth/src/v3.js'
import { computed, ref } from 'vue'

export default {

  setup() {
    const api = useApi()
    const toast = useToast()
    const content = ref(null)
    const auth = useAuth()

    const isAuth = computed(() => {
      return auth.check()
    })

    api.policy.fetchPrivacyPolicy().then(({data}) => {
      content.value = data?.content
    }).catch(() => {
      toast.error('Возникла ошибка')
    })
    return {
      content,
      isAuth
    }
  }
}
</script>
